<template>
<div v-if="type == 'text' || type == 'email' || type == 'password'">
  <v-text-field
    v-model="data"
    :label="showLabel"
    :type="type"
    filled
    hide-details="auto"
  ></v-text-field>
</div>

<div v-else-if="type == 'textarea'">
  <v-textarea
    :name="name"
    :label="showLabel"
    v-model="data"
    :rows="rows"
    filled
    hide-details="auto"
  ></v-textarea>
</div>

<div v-else-if="type == 'select'" class="form-group row">
    <label :id="'contact__' + name" class="col-24 col-sm-3 col-form-label">{{ showLabel }}</label>
    <div class="col-12 col-sm-9">
        <select v-model="data" class="form-control" :for="'contact__' + name">
            <option v-for="(item, key) in select" :key="key" :value="item">{{ item }}</option>
        </select>
    </div>
</div>
</template>

<script>
export default {
  name: 'ContactField',

  data() {
    return {
      data: ''
    };
  },

  props: {
    name: {
      default: '',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    type: {
      default: 'text',
      type: String,
    },
    rows: {
      default: 3,
      type: Number,
    },
    select: {
      default: () => [],
      type: Array,
    },
  },

  computed: {
    showLabel() {
      let { label } = this;

      if (this.$root.ContactForm.requiredFields.includes(this.name)) {
        label += '*';
      }

      return label;
    },
  },

  mounted() {
    this.$root.ContactForm.registerField(this);
  }
};
</script>
