<template>
<v-app>
  <v-main>
  <v-form>
    <div v-show="!success">
      <v-row>
        <v-col cols="24" lg="12">
          <contact-field name="firstname" label="Vorname" type="text" />
        </v-col>

        <v-col cols="24" lg="12">
          <contact-field name="lastname" label="Nachname" type="text" />
        </v-col>

        <v-col cols="24" lg="12">
          <contact-field name="email" label="E-Mail" type="email" />
        </v-col>

        <v-col cols="24" lg="12">
          <contact-field name="phone" label="Telefonnummer" type="text" />
        </v-col>

        <v-col cols="24" lg="24">
          <contact-field name="message" label="Nachricht" type="textarea" :rows="4" />
        </v-col>
      </v-row>

      <v-checkbox
        v-model="disclaimer"
        name="disclaimer"
        required
        class="text-small mb-1"
      >
        <template v-slot:label>
          <div>
          Ich habe die
          <a @click.stop :href="config.privacyUrl" title="Datenschutzerklärung" target="_blank">Datenschutzerklärung</a>
          zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
          meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre
          Einwilligung jederzeit für die Zukunft widerrufen.
          </div>
        </template>
      </v-checkbox>
      <contact-plot />

      <a href="#"
         title="Absenden"
         class="btn btn-primary btn-block"
         @click.prevent="sendMessage()"
      >
        Senden
      </a>
    </div>

    <v-alert type="success" v-if="success">Die Nachricht wurde erfolgreich versendet</v-alert>
    <v-alert type="error" v-else-if="error">Die Nachricht konnte nicht versendet werden</v-alert>
  </v-form>
  </v-main>
</v-app>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import ContactField from './ContactField.vue';
import ContactPlot from './ContactPlot.vue';

export default {
  name: 'ContactForm',

  data() {
    return {
      success: false,
      error: false,
      disclaimer: false,
      fields: []
    };
  },

  components: {
    ContactField,
    ContactPlot
  },

  created() {
    this.$root.ContactForm = this;
  },

  computed: {
    config() {
      return this.$root.config;
    },

    requiredFields() {
      let requiredFields = this.$prg.company.mail["__default"].requiredFields;
      if (this.$prg.company.mail[this.$root.config.name]) {
        requiredFields = {...requiredFields, ...this.$prg.company.mail[name].requiredFields};
      }

      return requiredFields;
    }
  },

  methods: {
    registerField(field) {
      this.fields.push(field);
    },

    sendMessage() {
      if (!this.disclaimer) {
        this.error = true;
        return;
      }

      const unixTS = Math.round((new Date()).getTime() / 1000);
      let postFields = {};
      postFields.timestamp = unixTS;

      this.fields.forEach((item) => {
        if (!item.data && item.data != false) {
          return;
        }

        postFields[item.name] = item.data;
      });

      axios.post('/wp-json/prg/v1/mail/send', {
        fields: postFields,
        name: this.config.name
      }).then((response) => {
        if (!response.data.error) {
          this.success = true;
        }
      });

      if (!this.success) {
        this.error = true;
      }
    }
  }
};
</script>
