import Vue from 'vue'
import "./sass/main.scss";
import deps from "./deps.json"
import MainCSS from './MainCSS.vue'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;
Vue.prototype.$prg = DOLLAR_PRG_VARIABLES; //eslint-disable-line

new Vue({
  vuetify,
  render: h => h(MainCSS)
});

for (const [key, value] of Object.entries(deps)) {
  const entry = require(`./entrypoint/${key}`);
  entry.default(value);
}
