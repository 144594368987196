<template>
<input v-model="data" type="checkbox" name="name" class="pPlot">
</template>

<script>
export default {
  name: 'ContactPlot',

  data() {
    return {
      name: 'plot',
      data: false,
    };
  },

  mounted() {
    this.$root.ContactForm.registerField(this);
  }
};
</script>

<style>
input[class = "pPlot"] {
  position: absolute;
  left: 999rem;
}
</style>
