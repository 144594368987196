import Swiper, { Navigation, Pagination } from "swiper";
import 'swiper/swiper-bundle.css';

export default () => {
  const elms = document.querySelectorAll(".swiper-container");
  if (!elms) {
    return;
  }

  Swiper.use([Navigation, Pagination]);
  for (const elm of elms) {
    let config = {};

    if (elm.dataset.config) {
      config = {...config, ...JSON.parse(elm.dataset.config)};
    }
    new Swiper(elm, config);
  }
}
