<template>
<div v-if="tools">
  <div ref="cookieNoticeDock" ></div>
  <div class="cookie-notice" v-if="show" :style="{position: position}">
    <div class="ast-container position-relative">
      <a href="#" title="Ablehnen" @click.prevent="declined" class="cookie-notice__declined"><i class="fas fa-times"></i></a>

      Um unsere Webseite für Sie optimal zu gestalten und fortlaufend verbessern zu können, verwenden wir Cookies.
      <div v-if="tools">
        Folgende Tools werden eingesetzt:

        <ul>
          <li v-for="(value, key) of tools" :key="key">
            {{ value }}
          </li>
        </ul>
      </div>

      <a href="#" class="btn btn-primary btn-block" @click.prevent="accepted">Ich akzeptiere</a>

      Wenn Sie der Verwendung von Cookies nicht zu stimmen werden nur technisch notwendige Cookies gesetzt.
      <strong>Weitere Informationen zu Cookies erhalten Sie in unserer <a :href="privacyUrl" target="_blank">Datenschutzerklärung</a>.</strong>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "CookieNotice",

  data() {
    return {
      show: false,
      position: "relative"
    }
  },

  computed: {
    privacyUrl() {
      return this.$root.privacyUrl;
    },

    tools() {
      let tools = [];
      if (this.$prg.company.gaKey) {
        tools.push("Google Analytics");
      }

      return tools;
    }
  },

  created() {
    if (Cookies.get("cookieNotice") === undefined) {
      this.show = true;
    }
  },

  mounted() {
    this.checkPositionState();
    window.addEventListener("scroll", () => {
      this.checkPositionState();
    });
  },

  methods: {
    accepted() {
      this.show = !this.show;
      Cookies.set("cookieNotice", true, {expires: 30});
    },

    declined() {
      this.show = !this.show;
      Cookies.set("cookieNotice", false, {expires: 30});
    },

    checkPositionState() {
      const cookieDock = this.$refs.cookieNoticeDock.offsetTop;
      if (cookieDock <= (window.scrollY + window.innerHeight)) {
        this.position = "relative";
      } else {
        this.position = "fixed";
      }
    }
  }
}
</script>

<style lang="scss">
.cookie-notice {
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 9999;
  box-shadow: 0px 1px 1px 1px #ccc;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__declined {
    position: absolute;
    right: .5rem;
    top: 0;
  }

  ul {
    margin-top: 1rem;
  }

  a.btn {
    display: block;
  }
}
</style>
