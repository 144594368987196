import Vue from "vue";
import ContactForm from "../components/contact/ContactForm";
import vuetify from '../plugins/vuetify';

export default () => {
  const elm = document.querySelector("#vue-contact");
  if (elm) {
    new Vue({
      computed: {
        privacyUrl() {
          if (elm.dataset.privacyurl) {
            return elm.dataset.privacyurl;
          }

          return "";
        },

        config() {
          let config = {};
          if (elm.dataset.config) {
            config = JSON.parse(elm.dataset.config);
          }

          return config;
        }
      },

      vuetify,
      render: h => h(ContactForm)
    }).$mount(elm);
  }
}
