import Vue from "vue";
import CookieNotice from "../components/cookie-notice/CookieNotice.vue";

export default () => {
  const elm = document.querySelector("#cookie-notice");
  if (elm) {
    new Vue({
      computed: {
        privacyUrl() {
          if (elm.dataset.privacyurl) {
            return elm.dataset.privacyurl;
          }

          return "";
        }
      },

      render: h => h(CookieNotice)
    }).$mount(elm);
  }
}
